import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { SvenTooltip } from "../SvenTooltip";
import { SvenIconButtonProps } from "./types";

interface StyleProps {
    flexDirection: "row" | "row-reverse" | "column" | "column-reverse";
    showText: boolean | undefined,
}

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
    createStyles({
        buttonLabel: {
            display: "flex",
            flexDirection: (props => props.flexDirection),
            "&>svg": {
                marginRight: (props => props.showText && (props.flexDirection === "row" || props.flexDirection === "row-reverse") ? 8 : 0),
                marginLeft: (props => props.showText && (props.flexDirection === "row" || props.flexDirection === "row-reverse") ? 8 : 0),
            },
        },
        tooltipTypography: {
            fontWeight: 600,
            fontSize: "0.8rem",
            paddingBottom: theme.spacing(0.5),
        },
    }),
);

export const SvenIconButton = React.forwardRef<HTMLElement, SvenIconButtonProps>(({
    children,
    disabled,
    flexDirection = "column",
    fontSizeOverride,
    placement = "bottom",
    secondaryText,
    showText,
    typographyStyle,
    text,
    typographyVariant = "button",
    variant = "text",
    classes,
    ...rest
}: SvenIconButtonProps, ref) => {
    const classesInternal = useStyles({ flexDirection, showText });

    const renderButton = () => {
        if (showText) {
            // square button
            return (
                <Button
                    {...rest}
                    variant={variant}
                    disabled={disabled}
                    classes={{ ...classes, label: classesInternal.buttonLabel }}
                >
                    {children}
                    <Typography
                        variant={typographyVariant}
                        style={{
                            ...typographyStyle,
                            fontSize: fontSizeOverride ?? "inherit",
                        }}
                    >
                        {text}
                    </Typography>
                </Button>
            );
        } else {
            // round icon button
            return (
                <IconButton
                    {...rest}
                    disabled={disabled}
                    classes={{ ...classes, label: classesInternal.buttonLabel }}
                >
                    {children}
                </IconButton>
            );
        }
    }

    if (!showText) {
        // By default disabled elements like <button> do not trigger user
        // interactions so a Tooltip will not activate on normal events like hover.
        // To accommodate disabled elements, add a simple wrapper element, such as a span.
        return (
            <SvenTooltip
                title={
                    <React.Fragment>
                        <Typography
                            variant="caption"
                            component="p"
                            className={classesInternal.tooltipTypography}
                        >
                            {text}
                        </Typography>
                        {secondaryText &&
                            <Typography variant="caption">
                                {secondaryText}
                            </Typography>}
                    </React.Fragment>
                }
                placement={placement}>
                <span>
                    {renderButton()}
                </span>
            </SvenTooltip>
        );
    } else if (showText && secondaryText) {
        // By default disabled elements like <button> do not trigger user
        // interactions so a Tooltip will not activate on normal events like hover.
        // To accommodate disabled elements, add a simple wrapper element, such as a span.
        return (
            <SvenTooltip
                title={
                    <React.Fragment>
                        <Typography
                            variant="caption"
                            component="p"
                            className={classesInternal.tooltipTypography}
                        >
                            {text}
                        </Typography>
                        <Typography variant="caption">
                            {secondaryText}
                        </Typography>
                    </React.Fragment>
                }
                placement={placement}
            >
                <span>
                    {renderButton()}
                </span>
            </SvenTooltip>
        );
    }
    else {
        return renderButton();
    }
});