// import { LocalStoragePersistenceService, ServiceWorkerUpdaterProps, withServiceWorkerUpdater } from "@3m1/service-worker-updater";
import DateFnsUtils from "@date-io/date-fns";
import useDarkMode from "@fisch0920/use-dark-mode";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createStyles, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { SnackbarProvider } from "notistack";
import * as React from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@material-ui/core/IconButton";
import { ErrorBoundary } from "./components/core/ErrorBoundary";
import { generateTheme } from "./theme";
import { CurrencyProvider } from "./contexts/CurrencyContext";
import { DensityProvider } from "./contexts/DensityContext";
import { LocalizationProvider, useLocalization } from "./contexts/LocalizationContext";
import { MessageBoxProvider } from "./contexts/MessageBoxContext";
import { PageTitleProvider } from "./contexts/PageTitleContext";
import { SnippetsProvider } from "./contexts/SnippetsContext";
import { SessionProvider, useSession } from "./contexts/SessionContext";
import { Swizzler } from "./components/core/Swizzler";
import { UrlHelper } from "./helpers/UrlHelper";
const NotAuthedContent = React.lazy(() => import("./NotAuthedContent").then(({ NotAuthedContent }) => ({ default: NotAuthedContent })));
const AuthedContent = React.lazy(() => import("./AuthedContent").then(({ AuthedContent }) => ({ default: AuthedContent })));

const useStyles = makeStyles((theme) =>
    createStyles({
        "@global": {
            /* text highlighting color - code for firefox */
            "::-moz-selection": {
                color: theme.palette.type === "dark" ? "#000000" : "#FFFFFF",
                background: theme.palette.secondary.main,
            },
            /* text highlighting color */
            "::selection": {
                color: theme.palette.type === "dark" ? "#000000" : "#FFFFFF",
                background: theme.palette.secondary.main,
            },

            /* Could not work out how to override the selected tree node style with JSS so have reverted to dirty css */
            ".light-mode .MuiTreeItem-root.Mui-selected>.MuiTreeItem-content .MuiTreeItem-label": {
                backgroundColor: "rgba(0, 0, 0, 0.08) !important",
            },

            /* Could not work out how to override the selected tree node style with JSS so have reverted to dirty css */
            ".dark-mode .MuiTreeItem-root.Mui-selected>.MuiTreeItem-content .MuiTreeItem-label": {
                backgroundColor: "rgba(225, 93, 29, 0.16) !important",
            },
            /* remove arrows/spinners from input type number */
            "input::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
            },
            /* remove arrows/spinners from input type number */
            "input::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
            },
            /* remove arrows/spinners from input type number - firefox*/
            "input[type=number]": {
                "appearance": "textfield",
                "-moz-appearance": "textfield",
            },
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
        },
    }),
);

const forceLogoutForRoutes: string[] = [
    UrlHelper.verifyAccountUrl,
    UrlHelper.verifyEmailUrl,
    UrlHelper.verifyInviteUrl,
];

const AppContent = () => {
    const classes = useStyles();
    const location = useLocation();
    const { currentLocale } = useLocalization();
    const { data, trySilentSignOut } = useSession();

    React.useEffect(() => {
        const found = forceLogoutForRoutes.filter(x => location.pathname.startsWith(x));
        if (found.length > 0) trySilentSignOut();
    }, [location, trySilentSignOut]);


    /** Remove the iframe warning thrown up  by ResizeObserver - loop limit exceeded
     * being caught by webpack. The error only gets caught in dev mode and can be ignored.
     */
    React.useEffect(() => {
        // Options for the observer (which mutations to observe)
        const config = { childList: true };

        // Callback function to execute when mutations are observed
        const callback = (mutations: MutationRecord[], observer: MutationObserver) => {
            for (const mutation of mutations) {
                if (mutation.type === "childList" && mutation.addedNodes && mutation.addedNodes.length > 0) {
                    for (let i = 0; i < mutation.addedNodes.length; i++) {
                        if (mutation.addedNodes[i].nodeName === "IFRAME") {
                            const iframe = mutation.addedNodes[i] as HTMLIFrameElement;
                            if (iframe.id === "webpack-dev-server-client-overlay") {
                                console.warn("Sven 🐐 Removed webpack-dev-server-client-overlay iframe. It probably had ResizeObserver - loop limit exceeded error. ")
                                iframe.style.display = "none";
                                //targetNode.removeChild(iframe);
                            }
                        }
                    }
                }
            }
        };

        // Create an observer instance linked to the callback function
        const observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        if (process.env.NODE_ENV !== "production") observer.observe(document.body, config);

        return () => {
            if (process.env.NODE_ENV !== "production") observer.disconnect();
        }
    }, []);

    if (data.status === "authenticating") {
        return (
            <Backdrop open={true} className={classes.backdrop}>
                <CircularProgress color="primary" />
            </Backdrop>
        );
    } else if (data.status === "authenticated") {
        return (
            <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={currentLocale.dateLocale}
            >
                <AuthedContent />
            </MuiPickersUtilsProvider>
        )
    } else {
        return <NotAuthedContent />;
    }
}

const App = () => {
    const { value } = useDarkMode();
    const myRef = React.useRef<SnackbarProvider | null>(null);

    const smDown = window.visualViewport!.width < 600 ? true : false; //useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <BrowserRouter>
            <ThemeProvider theme={generateTheme(value)}>
                <CssBaseline />
                <SnackbarProvider
                    maxSnack={3}
                    dense={smDown}
                    autoHideDuration={10000}
                    ref={myRef}
                    action={snackbarKey => <IconButton aria-label="dismiss" title="Dismiss" onClick={() => { myRef.current?.closeSnackbar(snackbarKey) }}><CloseRoundedIcon /></IconButton>}
                >
                    <SessionProvider>
                        <DensityProvider>
                            <CurrencyProvider>
                                <LocalizationProvider>
                                    <PageTitleProvider>
                                        <MessageBoxProvider>
                                            <ErrorBoundary>
                                                <SnippetsProvider>
                                                    <ErrorBoundary>
                                                        <React.Suspense fallback={<Swizzler text="Loading..." />}>
                                                            <AppContent />
                                                        </React.Suspense>
                                                    </ErrorBoundary>
                                                </SnippetsProvider>
                                            </ErrorBoundary>
                                        </MessageBoxProvider>
                                    </PageTitleProvider>
                                </LocalizationProvider>
                            </CurrencyProvider>
                        </DensityProvider>
                    </SessionProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
}

App.whyDidYoyRender = true;
export { App }
// export default withServiceWorkerUpdater(App, {
//     persistenceService: new LocalStoragePersistenceService('buildprojex')
// });