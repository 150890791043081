import { IColorProfile } from "./types";
import { green, lightBlue, red, amber, yellow, grey } from "@material-ui/core/colors";
import { LogoWithTextAltIcon } from "../components/icons";
import { darken } from "@material-ui/core/styles";

export class OriginalTheme implements IColorProfile {

    private readonly _buildProjexBlack = "#303335";

    public appBar(isDarkMode: boolean) {
        return isDarkMode ? darken(grey[800], 0.3) : this._buildProjexBlack;
    }

    public appBarContrastText(isDarkMode: boolean) {
        return "#FFFFFF";
    }

    public primary(isDarkMode: boolean) {
        return "#E15D1D";
        // return isDarkMode ? grey[800] : "#303335";
        // return "#303335";
    }

    public secondary(isDarkMode: boolean) {
        return "#1DA1E1";//"#5D1DE1";
    }

    public error(isDarkMode: boolean) {
        return isDarkMode ? red[500] : red[700];
    }

    public warning(isDarkMode: boolean) {
        return isDarkMode ? yellow[800] : amber[700];
        // return isDarkMode ? yellow[800] : amber[700];
    }

    public info(isDarkMode: boolean) {
        return isDarkMode ? lightBlue[600] : lightBlue[700];
    }

    public success(isDarkMode: boolean) {
        return isDarkMode ? green[800] : green[500];
    }

    public logo() {
        return "#E15D1D";
    }

    public backgroundDefault(isDarkMode: boolean) {
        return isDarkMode ? grey[900] : "#FFFFFF";
        // return isDarkMode ? lighten(ThemeHelper.buildProjexBlack, 0.03) : "#FFFFFF";
    }

    public backgroundPaper(isDarkMode: boolean) {
        return isDarkMode ? grey[900] : "#FFFFFF";
        // return isDarkMode ? lighten(ThemeHelper.buildProjexBlack, 0.03) : "#FFFFFF";
    }

    public standardCardBackground(isDarkMode: boolean) {
        return isDarkMode ? darken(grey[900], 0.3) : this._buildProjexBlack;
    }

    public externalStylesheets() {
        return [];
    }

    public fontFamily() {
        return [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ];
    };

    public logoComponent(isDarkMode: boolean) {
        return <LogoWithTextAltIcon viewBox="0 0 2634 631" style={{ width: 171.14, height: 41 }} />;
    }
}