import { createTheme, responsiveFontSizes, Theme } from "@material-ui/core/styles";
import { OriginalTheme } from "./Original";
import { IColorProfile } from "./types";
// import { MkmTheme } from "./Mkm";
// import { MuiTheme } from "./Mui";
// import { WickesTheme } from "./Wickes";
// import { NineteenEightyTwoTheme } from "./NineteenEightyTwo";
// import { MayflowerTheme } from "./Mayflower";


export const currentTheme: IColorProfile = new OriginalTheme();

export const generateTheme = (isDarkMode?: boolean): Theme => {

    const head = document.getElementsByTagName("head")[0];
    if (head) {
        currentTheme.externalStylesheets().forEach(stylesheet => {
            const link = document.createElement("link");
            link.setAttribute("rel", "stylesheet");
            link.setAttribute("type", "text/css");
            link.setAttribute("href", stylesheet);
            head.appendChild(link);
        });
    }
    const palletType = isDarkMode ? "dark" : "light";


    // Segoe UI doe not have a 500 weight representation
    // Firefox rounds down to 400 and Chromium rounds up to 600
    // 600 looks much better
    let theme = createTheme({
        palette: {
            type: palletType,
            primary: {
                main: currentTheme.primary(!!isDarkMode),
            },
            secondary: {
                main: currentTheme.secondary(!!isDarkMode)
            },
            error: {
                main: currentTheme.error(!!isDarkMode),
            },
            warning: {
                main: currentTheme.warning(!!isDarkMode),
            },
            info: {
                main: currentTheme.info(!!isDarkMode),
            },
            success: {
                main: currentTheme.success(!!isDarkMode),
            },
            background: {
                default: currentTheme.backgroundDefault(!!isDarkMode),
                paper: currentTheme.backgroundPaper(!!isDarkMode),
            },
        },
        shape: {
            borderRadius: 4,
        },
        typography: {
            fontFamily: currentTheme.fontFamily().join(','),
            fontWeightMedium: 600,
            h6: {
                fontWeight: 600,
            },
            subtitle2: {
                fontWeight: 600,
            },
            button: {
                fontWeight: 600,
            }
        },
    });

    Object.assign(theme, {
        overrides: {
            MuiButton: {
                root: {
                    textTransform: "none",
                },
            },
            MuiToggleButton: {
                root: {
                    textTransform: "none",
                },
            },
            MuiFab: {
                root: {
                    textTransform: "none",
                },
            },
            MuiTableCell: {
                root: {
                    padding: 8,
                },
            },
            MuiTypography: {
                button: {
                    textTransform: "none",
                },
            },
            MuiCardHeader: {
                title: {
                    ...theme.typography.h6,
                    userSelect: "none",
                },
            },
            MuiFormControlLabel: {
                label: {
                    userSelect: "none",
                },
            },
            MuiListSubheader: {
                root: {
                    userSelect: "none",
                },
            },
            MuiTooltip: {
                tooltip: {
                    userSelect: "none",
                },
            },
            MuiRichTextEditor: {
                root: {
                    border: 1,
                    // borderColor: theme.palette.text.primary,
                    borderRadius: theme.shape.borderRadius,
                    borderStyle: "solid",
                    flexGrow: 1,
                },
                container: {
                    display: "flex",
                    flexDirection: "column-reverse",
                },
                toolbar: {
                    borderTop: 1,
                    borderTopColor: theme.palette.divider,
                    borderTopStyle: "solid",
                },
                placeHolder: {
                    position: "static",
                    padding: theme.spacing(1, 1, 2, 1),
                },
                editor: {
                    overflowX: "auto",
                    maxHeight: 152,
                },
                editorContainer: {
                    padding: theme.spacing(1, 1, 2, 1),
                },
            },
            // in dark mode set the focused text box label to orange as you cannot see the the black
            // on a dark background
            MuiInputLabel: {
                root: {
                    userSelect: "none",
                },
                outlined: {
                    "&$focused": {
                        //color: isDarkMode ? theme.palette.secondary.main : theme.palette.primary.main,
                    },
                },
            },
            MuiOutlinedInput: {
                // in dark mode set the focused text box border to orange as you cannot see the the black
                // on a dark background
                root: {
                    "&$focused $notchedOutline": {
                        //borderColor: isDarkMode ? theme.palette.secondary.main : theme.palette.primary.main,
                    },
                },
                // autofill on the password dialog looked horrid
                input: {
                    "&:-webkit-autofill": {
                        "-webkit-box-shadow": `0 0 0 100px ${theme.palette.background.paper} inset`,
                        borderRadius: 0,
                    },
                }
            },
        },
    });
    theme = responsiveFontSizes(theme);
    return theme;
}

export enum SocialColors {
    Facebook = "#4267b2",
    Instagram = "#101010",
    Linkedin = "#0A66C2",
    Pinterest = "#E60023",
    Twitter = "#000000",
    Youtube = "#FF0000",
};

