import "./wdyr"; // <-- first import

// import { onServiceWorkerUpdate } from "@3m1/service-worker-updater";
import * as ReactDOM from "react-dom";
import * as React from "react";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// TODO: See if we can enable strict mode once MUI v5 is implemented
// REACT 17
//ReactDOM.render(
//    <React.StrictMode>
//        <App />
//    </React.StrictMode>,
//    document.getElementById("root")
//);

// REACT 17
ReactDOM.render(
    <App />,
    document.getElementById("root")
);



// // REACT 18
// import { createRoot } from "react-dom/client";
// const container = document.getElementById("root");
// const root = createRoot(container!);
// root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    // onUpdate: onServiceWorkerUpdate,
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();