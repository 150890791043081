import { CoreApiClientExt } from "../ApiClient/CoreApiClient";
import { ActivityOperation, ActivityResponse, KnowledgeBaseArticleResponse, BatchTagRequest, BatchTagResponse, TagResponse, ContactResponse, CreateMemberFromContactRequest, CreateMemberRequest, DeletedContactResponse, EntityNotificationEventName, FileParameter, MemberResponse, NotificationSettingsResponse, ResponseResult, RoleResponse, UpdateContactRequest, UpdateMemberRequest, UpdateNotificationSettingsRequest, UpdateWorkspaceRequest, WorkspaceResponse, WorkspaceSummaryResponse, WorkScheduleResponse, CultureInfoResponse, ForgotPasswordRequest, CreateWorkspaceRequest, GenerateTokenRequest, TokenResponse, RefreshTokenRequest, CreateApprovalRequest, ApprovalResponse } from "../ApiClient/Schema";
import { BaseCloudManager } from "./BaseCloudManager";


export class CoreCloudManager extends BaseCloudManager {
    private _client: CoreApiClientExt;
    private _anonymousClient: CoreApiClientExt;

    constructor(accessTokenFactory: () => Promise<string>) {
        super();
        this._client = new CoreApiClientExt(accessTokenFactory);
        this._anonymousClient = new CoreApiClientExt(accessTokenFactory);
        this._anonymousClient.requireToken = false;
    }

    public addApproval = async (request: CreateApprovalRequest, signal?: AbortSignal): Promise<ResponseResult<ApprovalResponse>> => {
        try {
            return this.handleSuccessWithEvent(await this._client.approvalsPOST(request, signal), EntityNotificationEventName.ApprovalCreated);
        }
        catch (ex: any) {
            return this.handleError(ex, this.addApproval.name);
        }
    }
    public addContact = async (request: UpdateContactRequest, signal?: AbortSignal): Promise<ResponseResult<ContactResponse>> => {
        try {
            return this.handleSuccessWithEvent(await this._client.contactsPOST(request, signal), EntityNotificationEventName.ContactCreated);
        }
        catch (ex: any) {
            return this.handleError(ex, this.addContact.name);
        }
    }
    public addMember = async (request: CreateMemberRequest, signal?: AbortSignal): Promise<ResponseResult<MemberResponse>> => {
        try {
            return this.handleSuccessWithEvent(await this._client.membersPOST(request, signal), EntityNotificationEventName.MemberCreated);
        }
        catch (ex: any) {
            return this.handleError(ex, this.addMember.name);
        }
    }
    public addMemberFromContact = async (request: CreateMemberFromContactRequest, signal?: AbortSignal): Promise<ResponseResult<MemberResponse>> => {
        try {
            return this.handleSuccessWithEvent(await this._client.fromContact(request, signal), EntityNotificationEventName.MemberCreated);
        }
        catch (ex: any) {
            return this.handleError(ex, this.addMemberFromContact.name);
        }
    }
    public addWorkspace = async (request: CreateWorkspaceRequest, signal?: AbortSignal): Promise<ResponseResult<WorkspaceResponse>> => {
        try {
            const response = await this._anonymousClient.workspaces(request, signal);
            return this.handleSuccess(response);
        }
        catch (ex: unknown) {
            return this.handleError(ex, this.addWorkspace.name);
        }
    }
    public batchEditContactTags = async (request: BatchTagRequest, signal?: AbortSignal): Promise<ResponseResult<BatchTagResponse>> => {
        try {
            return this.handleSuccessWithEvent(await this._client.batchContactTagsPOST(request, signal), EntityNotificationEventName.TagBatchEdited);
        }
        catch (ex: any) {
            return this.handleError(ex, this.batchEditContactTags.name);
        }
    }
    public changeEmail = async (email: string): Promise<ResponseResult<void>> => {
        try {
            return this.handleSuccess(await this._client.requestChangeEmail({ email: email }));
        }
        catch (ex: any) {
            return this.handleError(ex, this.changeEmail.name);
        }
    }
    /** set new password as part of password reset process */
    public changePassword = async (e: string, password: string, t: string, signal?: AbortSignal): Promise<ResponseResult<void>> => {
        try {
            const response = await this._anonymousClient.resetPassword({
                e: e,
                password: password,
                t: t,
            }, signal);
            return this.handleSuccess(response);
        }
        catch (ex) {
            return this.handleError(ex, this.changePassword.name);
        };
    }
    public changePasswordForAuthedUser = async (oldPassword: string, newPassword: string): Promise<ResponseResult<TokenResponse>> => {
        try {
            const response = await this._client.changePassword({ currentPassword: oldPassword, newPassword: newPassword });
            return this.handleSuccess(response);
        }
        catch (ex: any) {
            return this.handleError(ex, this.changePasswordForAuthedUser.name);
        }
    }
    public deleteContact = async (contactId: string, signal?: AbortSignal): Promise<ResponseResult<boolean>> => {
        try {
            await this._client.contactsDELETE(contactId, signal);
            document.dispatchEvent(new CustomEvent(EntityNotificationEventName.ContactDeleted, {
                detail: {
                    contactId: contactId,
                    kind: "DeletedContactResponse",
                } as DeletedContactResponse
            }));
            return this.handleSuccess(true);
        }
        catch (ex: any) {
            return this.handleError(ex, this.deleteContact.name);
        }
    }
    public deleteContactAvatar = async (contactId: string, signal?: AbortSignal): Promise<ResponseResult<ContactResponse>> => {
        try {
            return this.handleSuccessWithEvent(await this._client.avatarDELETE(contactId, signal), EntityNotificationEventName.ContactUpdated);
        }
        catch (ex: any) {
            return this.handleError(ex, this.deleteContactAvatar.name);
        }
    }
    public deleteWorkspaceLogo = async (signal?: AbortSignal): Promise<ResponseResult<WorkspaceResponse>> => {
        try {
            return this.handleSuccessWithEvent(await this._client.logoDELETE(signal), EntityNotificationEventName.WorkspaceUpdated);
        }
        catch (ex: any) {
            return this.handleError(ex, this.deleteWorkspaceLogo.name);
        }
    }
    public getContact = async (contactId: string, signal?: AbortSignal): Promise<ResponseResult<ContactResponse>> => {
        try {
            return this.handleSuccess(await this._client.contactsGET(contactId, signal));
        }
        catch (ex: any) {
            return this.handleError(ex, this.getContact.name);
        }
    }
    public getContactTags = async (signal?: AbortSignal): Promise<ResponseResult<TagResponse[]>> => {
        try {
            return this.handleSuccess(await this._client.contactTagsAll(signal));
        }
        catch (ex: any) {
            return this.handleError(ex, this.getContactTags.name);
        }
    }
    public getContacts = async (signal?: AbortSignal): Promise<ResponseResult<ContactResponse[]>> => {
        try {
            return this.handleSuccess(await this._client.contactsAll(signal));
        }
        catch (ex: any) {
            return this.handleError(ex, this.getContacts.name);
        }
    }
    public getCultureInfos = async (signal?: AbortSignal): Promise<ResponseResult<CultureInfoResponse[]>> => {
        try {
            return this.handleSuccess(await this._client.cultureInfos(signal));
        }
        catch (ex: any) {
            return this.handleError(ex, this.getCultureInfos.name);
        }
    }
    public getKnowledgeBaseArticle = async (articleId: number, signal?: AbortSignal): Promise<ResponseResult<KnowledgeBaseArticleResponse>> => {
        try {
            return this.handleSuccess(await this._client.articles(articleId, signal));
        }
        catch (ex: any) {
            return this.handleError(ex, this.getKnowledgeBaseArticle.name);
        }
    }
    public getMember = async (memberId: string, signal?: AbortSignal): Promise<ResponseResult<MemberResponse>> => {
        try {
            return this.handleSuccess(await this._client.membersGET(memberId, signal));
        }
        catch (ex: any) {
            return this.handleError(ex, this.getMember.name);
        }
    }
    public getMembers = async (signal?: AbortSignal): Promise<ResponseResult<MemberResponse[]>> => {
        try {
            return this.handleSuccess(await this._client.membersAll(signal));
        }
        catch (ex: any) {
            return this.handleError(ex, this.getMembers.name);
        }
    }
    public getMyNotificationSettings = async (signal?: AbortSignal): Promise<ResponseResult<NotificationSettingsResponse>> => {
        try {
            return this.handleSuccess(await this._client.notificationsGET(signal));
        }
        catch (ex: any) {
            return this.handleError(ex, this.getMyNotificationSettings.name);
        }
    }
    public getActivities = async (from: Date | undefined, to: Date | undefined, channelId: string | undefined, fileId: string | undefined, folderId: string | undefined, memberId: string | undefined, projectId: string | undefined, taskId: string | undefined, notificationTypes: string[] | undefined, operations: ActivityOperation[] | undefined, rank: number | undefined, signal?: AbortSignal): Promise<ResponseResult<ActivityResponse[]>> => {
        try {
            return this.handleSuccess(await this._client.activities(from, to, channelId, fileId, folderId, memberId, projectId, taskId, operations, signal));
        }
        catch (ex: any) {
            return this.handleError(ex, this.getActivities.name);
        }
    }
    public getRoles = async (signal?: AbortSignal): Promise<ResponseResult<RoleResponse[]>> => {
        try {
            return this.handleSuccess(await this._client.roles(signal));
        }
        catch (ex: any) {
            return this.handleError(ex, this.getRoles.name);
        }
    }
    public getSnippet = async (snippetId: string, signal?: AbortSignal): Promise<ResponseResult<string>> => {
        try {
            return this.handleSuccess(await this._anonymousClient.snippets(snippetId, signal));
        }
        catch (ex: any) {
            return this.handleError(ex, this.getSnippet.name);
        }
    }
    public getUser = async (signal?: AbortSignal): Promise<ResponseResult<MemberResponse>> => {
        try {
            const response = await this._client.me(signal);
            return this.handleSuccess(response);
        }
        catch (ex) {
            return this.handleError(ex, this.getUser.name);
        }
    }
    public getWorkSchedules = async (purpose: "gantt" | "notification", signal?: AbortSignal): Promise<ResponseResult<WorkScheduleResponse[]>> => {
        try {
            return this.handleSuccess(await this._client.workSchedules(purpose, signal));
        }
        catch (ex: any) {
            return this.handleError(ex, this.getWorkSchedules.name);
        }
    }
    public getWorkspace = async (signal?: AbortSignal): Promise<ResponseResult<WorkspaceResponse>> => {
        try {
            return this.handleSuccess(await this._client.currentWorkspaceGET(signal));
        }
        catch (ex: any) {
            return this.handleError(ex, this.getWorkspace.name);
        }
    }
    public getWorkspaces = async (signal?: AbortSignal): Promise<ResponseResult<WorkspaceSummaryResponse[]>> => {
        try {
            return this.handleSuccess(await this._client.workspacesAll(signal));
        }
        catch (ex: any) {
            return this.handleError(ex, this.getWorkspaces.name);
        }
    }
    public refreshToken = async (request: RefreshTokenRequest, signal?: AbortSignal): Promise<ResponseResult<TokenResponse>> => {
        try {
            const response = await this._anonymousClient.refreshToken(request, signal);
            return this.handleSuccess(response);
        }
        catch (ex) {
            return this.handleError(ex, this.refreshToken.name);
        }
    }
    public resendMemberInvite = async (memberId: string, signal?: AbortSignal): Promise<ResponseResult<boolean>> => {
        try {
            await this._client.resendInvite(memberId, signal);
            return this.handleSuccess(true);
        }
        catch (ex: any) {
            return this.handleError(ex, this.resendMemberInvite.name);
        }
    }
    /** request a password reset for an unauthenticated user */
    public resetPassword = async (email: string, signal?: AbortSignal): Promise<ResponseResult<void>> => {
        try {
            const response = await this._anonymousClient.forgotPassword({ email: email } as ForgotPasswordRequest, signal);
            return this.handleSuccess(response);
        }
        catch (ex) {
            return this.handleError(ex, this.resetPassword.name);
        }
    }
    public signIn = async (request: GenerateTokenRequest, signal?: AbortSignal): Promise<ResponseResult<TokenResponse>> => {
        try {
            const response = await this._anonymousClient.generateToken(request, signal);
            return this.handleSuccess(response);
        }
        catch (ex) {
            return this.handleError(ex, this.signIn.name);
        }
    }
    public signOut = async (refreshToken: string, signal?: AbortSignal): Promise<ResponseResult<void>> => {
        try {
            const response = await this._anonymousClient.revokeToken({ refreshToken: refreshToken }, signal);
            return this.handleSuccess(response);
        }
        catch (ex) {
            return this.handleError(ex, this.signOut.name);
        }
    }
    public updateContact = async (contactId: string, request: UpdateContactRequest, signal?: AbortSignal): Promise<ResponseResult<ContactResponse>> => {
        try {
            return this.handleSuccessWithEvent(await this._client.contactsPUT(contactId, request, signal), EntityNotificationEventName.ContactUpdated);
        }
        catch (ex: any) {
            return this.handleError(ex, this.updateContact.name);
        }
    }
    public updateContactAvatar = async (contactId: string, file: File, signal?: AbortSignal): Promise<ResponseResult<ContactResponse>> => {
        try {
            return this.handleSuccessWithEvent(await this._client.avatarPUT(contactId, { fileName: file.name, data: file } as FileParameter, signal), EntityNotificationEventName.ContactUpdated);
        }
        catch (ex: any) {
            return this.handleError(ex, this.updateContactAvatar.name);
        }
    }
    public updateMember = async (memberId: string, request: UpdateMemberRequest, signal?: AbortSignal): Promise<ResponseResult<MemberResponse>> => {
        try {
            return this.handleSuccessWithEvent(await this._client.membersPUT(memberId, request, signal), EntityNotificationEventName.MemberUpdated);
        }
        catch (ex: any) {
            return this.handleError(ex, this.updateMember.name);
        }
    }
    public updateMyNotificationSettings = async (request: UpdateNotificationSettingsRequest, signal?: AbortSignal): Promise<ResponseResult<NotificationSettingsResponse>> => {
        try {
            return this.handleSuccess(await this._client.notificationsPUT(request, signal))
        }
        catch (ex: any) {
            return this.handleError(ex, this.updateMyNotificationSettings.name);
        }
    }
    public updateWorkspace = async (request: UpdateWorkspaceRequest, signal?: AbortSignal): Promise<ResponseResult<WorkspaceResponse>> => {
        try {
            return this.handleSuccessWithEvent(await this._client.currentWorkspacePUT(request, signal), EntityNotificationEventName.WorkspaceUpdated);
        }
        catch (ex: any) {
            return this.handleError(ex, this.updateWorkspace.name);
        }
    }
    public updateWorkspaceLogo = async (file: File, signal?: AbortSignal): Promise<ResponseResult<WorkspaceResponse>> => {
        try {
            return this.handleSuccessWithEvent(await this._client.logoPUT({ fileName: file.name, data: file } as FileParameter, signal), EntityNotificationEventName.WorkspaceUpdated);
        }
        catch (ex: any) {
            return this.handleError(ex, this.updateWorkspaceLogo.name);
        }
    }
    public verifyAccount = async (e: string, t: string, signal?: AbortSignal): Promise<ResponseResult<void>> => {
        try {
            const response = await this._anonymousClient.verifyAccount({
                e: e,
                t: t,
            }, signal);
            return this.handleSuccess(response)
        }
        catch (ex) {
            return this.handleError(ex, this.verifyAccount.name);
        }
    }
    public verifyEmail = async (c: string, e: string, t: string, signal?: AbortSignal): Promise<ResponseResult<void>> => {
        try {
            // TokenManager.clearTokens();
            const response = await this._anonymousClient.verifyEmail({
                c: c,
                e: e,
                t: t,
            }, signal);
            return this.handleSuccess(response);
        }
        catch (ex) {
            return this.handleError(ex, this.verifyEmail.name);
        }
    }
    public verifyInvite = async (e: string, t: string, password: string, signal?: AbortSignal): Promise<ResponseResult<void>> => {
        try {
            // TokenManager.clearTokens();
            const response = await this._anonymousClient.verifyInvite({
                e: e,
                password: password,
                t: t,
            }, signal);
            return this.handleSuccess(response);
        }
        catch (ex) {
            return this.handleError(ex, this.verifyInvite.name);
        }
    }
}