export interface MemberFilters {
    isActiveFilter: boolean[];
    roleFilter: string[];
}

export const defaultMemberFilters: MemberFilters = {
    isActiveFilter: [],
    roleFilter: [],
}

export const hasMemberFilters = (filters: MemberFilters) => {
    return filters.isActiveFilter.length > 0 || filters.roleFilter.length > 0;
}
