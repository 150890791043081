import * as React from "react";
import { DensityProviderProps, DensitySetting, IDensityContext } from "./types";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const spacious: DensitySetting = { density: "spacious", gridSpacing: 4, };
const compact: DensitySetting = { density: "compact", gridSpacing: 2, }

const DensityContext = React.createContext<IDensityContext>({
    densitySetting: spacious,
});

const DensityProvider: React.FC<DensityProviderProps> = ({ children }) => {
    // const theme = useTheme();
    // const smDown = useMediaQuery(theme.breakpoints.down("sm"));
    // const smUp = useMediaQuery(theme.breakpoints.up("sm"));
    /**
     *  The primary input mechanism includes an accurate pointing device, such as a mouse.
     *  This will mean that hovering is possible.
     */
    const hasMouse = useMediaQuery("@media (pointer: fine)");

    const [densitySetting, setDensitySetting] = React.useState<DensitySetting>(spacious);

    // React.useEffect(() => {
    //     // setDensitySetting(smDown ? spacious : compact);
    //     setDensitySetting(smUp ? compact : spacious);
    // }, [smUp]);


    React.useEffect(() => {
        // setDensitySetting(smDown ? spacious : compact);
        setDensitySetting(hasMouse ? compact : spacious);
    }, [hasMouse]);

    return (
        <DensityContext.Provider value={{ densitySetting }}>
            {children}
        </DensityContext.Provider>
    );
}

const useDensity = () => React.useContext(DensityContext);

export { useDensity, DensityProvider };