import Button from "@material-ui/core/Button";

import { SubmitButtonProps } from "./types";

const SubmitButton = ({
    autofocus,
    color = "primary",
    disabled,
    isBusy,
    onSubmit,
    style,
    text = "Submit",
}: SubmitButtonProps) => {
    return (
        <Button
            autoFocus={autofocus}
            color={color}
            disabled={isBusy || disabled}
            variant="contained"
            style={style}
            onClick={async (e) => await onSubmit(e)}
        >
            {text}
        </Button>

    );
}

export { SubmitButton };