import * as React from 'react';
import { createSvgIcon } from "@material-ui/core/utils";
/** taken from MUI v6 GradingRounded.js */
export default createSvgIcon(
  <React.Fragment>
    <g transform="scale(0.75) translate(4 2)">
      <path d="M5 7h14c.55 0 1 .45 1 1s-.45 1-1 1H5c-.55 0-1-.45-1-1s.45-1 1-1m0 6h14c.55 0 1-.45 1-1s-.45-1-1-1H5c-.55 0-1 .45-1 1s.45 1 1 1m0 4h5c.55 0 1-.45 1-1s-.45-1-1-1H5c-.55 0-1 .45-1 1s.45 1 1 1m0 4h5c.55 0 1-.45 1-1s-.45-1-1-1H5c-.55 0-1 .45-1 1s.45 1 1 1m10.41-2.83-.71-.71a.996.996 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41l1.42 1.42c.39.39 1.02.39 1.41 0l3.17-3.17c.39-.39.39-1.02 0-1.41a.996.996 0 0 0-1.41 0z" />
    </g>
    <rect x="4" y="1" width="16" height="22" rx="2" fill="transparent" stroke="currentColor" strokeWidth="2" />
  </React.Fragment>
  , "TimesheetAdmin");