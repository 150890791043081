import enAU from "date-fns/locale/en-AU";
import enCA from "date-fns/locale/en-CA";
import enGB from "date-fns/locale/en-GB";
import enIE from "date-fns/locale/en-IE";
import enNZ from "date-fns/locale/en-NZ";
import enUS from "date-fns/locale/en-US";
import enZA from "date-fns/locale/en-ZA";
import * as React from "react";
import { CultureInfoResponseExt, DateFormat, ILocalizationContext, LocaleInfo, LocalizationProviderProps } from "./types";
import { formatInTimeZone, getTimezoneOffset } from "date-fns-tz";
import isToday from "date-fns/isToday";
import isTomorrow from "date-fns/isTomorrow";
import isYesterday from "date-fns/isYesterday";
import differenceInDays from "date-fns/differenceInDays";
import format from "date-fns/format";
import add from "date-fns/add";
import sub from "date-fns/sub";
import { DateRange, FormattedDateRange, NoProviderError, PredefinedDateRangeId, predefinedDateRanges, SvenDateRange } from "../../types";
import { startOfDecade, startOfMonth, startOfQuarter, startOfToday, startOfTomorrow, startOfWeek, startOfYear, startOfYesterday, subSeconds } from "date-fns";
import { Constants } from "../../Globals";

const defaultLocale: LocaleInfo = {
    dateLocale: enGB,
    localeString: "en-GB",
    localeFolder: "en-GB",
    ianaTimeZone: "Europe/London",
    deviceIanaTimeZone: "Europe/London",
    timeSeparator: ":",
    is24Hour: true,
    datePickerFormat: "dd/MM/yyyy",
};

const LocalizationContext = React.createContext<ILocalizationContext>({
    setLocale: () => { throw new NoProviderError() },
    getDeviceCultureInfoOrFallback: () => { throw new NoProviderError() },
    getRelativeDateName: () => { throw new NoProviderError() },
    currentLocale: defaultLocale,
    formatDate: () => { throw new NoProviderError() },
    getOutgoingAdjustedDate: () => { throw new NoProviderError() },
    getTimeZoneOffsetBetweenDeviceAndWorkspace: () => { throw new NoProviderError() },
    getIncomingAdjustedDate: () => { throw new NoProviderError() },
    cultureInfos: [],
    getCultureInfo: () => { throw new NoProviderError() },
    calculateDateRange: () => { throw new NoProviderError() },
});

const LocalizationProvider: React.FC<LocalizationProviderProps> = ({ children }) => {

    const [currentLocale, setCurrentLocale] = React.useState<LocaleInfo>(defaultLocale);
    const [cultureInfos, setCultureInfos] = React.useState<CultureInfoResponseExt[]>([]);

    React.useEffect(() => {
        const asyncFunction = async () => {
            try {
                // CDN will have cached regions.json so increment number to get fresh version
                const response = await fetch("/locale/regions.json?v=1", { signal: abortController.signal });
                const data: CultureInfoResponseExt[] = await response.json();
                setCultureInfos(data.filter(x => x.published));
            }
            catch (ex: any) {
                //console.error("CultureInfoSelect error:", ex);
            }
        }
        const abortController = new AbortController();
        asyncFunction();
        return () => {
            abortController.abort();
        }
    }, []);


    const getDeviceCultureInfoOrFallback = React.useCallback((): CultureInfoResponseExt => {
        const id = (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
        const deviceCultureInfo = cultureInfos.find(x => x.id === id);
        if (deviceCultureInfo) return deviceCultureInfo;
        return cultureInfos.find(x => x.id === defaultLocale.localeString)!;
    }, [cultureInfos]);

    const getCultureInfo = React.useCallback((id: string): CultureInfoResponseExt | undefined => cultureInfos.find(x => x.id === id), [cultureInfos]);

    const getTimeZoneOffsetBetweenDeviceAndWorkspace = React.useCallback((utcDate: Date): number => {
        const workingTimeZoneOffset = getTimezoneOffset(currentLocale.ianaTimeZone, utcDate);
        const deviceTimeZoneOffset = getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone, utcDate);
        const milliseconds = deviceTimeZoneOffset - workingTimeZoneOffset;
        return milliseconds;
    }, [currentLocale]);

    const getIncomingAdjustedDate = React.useCallback((deviceDate: Date): Date => {
        const milliseconds = getTimeZoneOffsetBetweenDeviceAndWorkspace(deviceDate);
        return sub(deviceDate, { seconds: milliseconds / 1000 })
    }, [getTimeZoneOffsetBetweenDeviceAndWorkspace]);

    const getOutgoingAdjustedDate = React.useCallback((date: Date): Date => {
        const milliseconds = getTimeZoneOffsetBetweenDeviceAndWorkspace(date);
        return add(date, { seconds: milliseconds / 1000 })
    }, [getTimeZoneOffsetBetweenDeviceAndWorkspace]);

    const setLocale = React.useCallback(async (localeString: string, timeZone: string) => {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let localeFolder = "en-GB";
        let datePickerFormat = "dd/MM/yyyy";
        let dateLocale = enGB;
        let dateFnsLocaleName = "en-GB";
        let is24Hour = true;
        let timeSeparator = ":";

        const cultureInfo = getCultureInfo(localeString);
        if (cultureInfo) {
            if (cultureInfo.languageFolder) {
                localeFolder = cultureInfo.languageFolder;
            }

            if (cultureInfo.datePickerFormat) {
                datePickerFormat = cultureInfo.datePickerFormat;
            }

            if (cultureInfo.dateFolder && cultureInfo.dateFolder !== dateFnsLocaleName) {
                // dateLocale = await import(
                //     /* webpackMode: "lazy", webpackChunkName: "df-[index]", webpackExclude: /_lib/ */
                //     `date-fns/locale/${cultureInfo.dateFolder}/index.js`
                // );
                switch (cultureInfo.dateFolder) {
                    case "en-GB":
                        dateLocale = enGB;
                        is24Hour = true
                        break;

                    case "en-AU":
                        dateLocale = enAU;
                        is24Hour = false;
                        break;

                    case "en-CA":
                        dateLocale = enCA;
                        is24Hour = false;
                        break;

                    case "en-IE":
                        dateLocale = enIE;
                        is24Hour = true;
                        break;

                    case "en-NZ":
                        dateLocale = enNZ;
                        is24Hour = false;
                        break;

                    case "en-ZA":
                        dateLocale = enZA;
                        is24Hour = true;
                        break;

                    case "en-US":
                        dateLocale = enUS;
                        is24Hour = false;
                        break;
                }
            }
        }

        setCurrentLocale({
            dateLocale: dateLocale,
            localeString: localeString,
            localeFolder: localeFolder,
            ianaTimeZone: timeZone,
            is24Hour: is24Hour,
            datePickerFormat: datePickerFormat,
            deviceIanaTimeZone: tz,
            timeSeparator: timeSeparator,
        });

    }, [getCultureInfo]);


    const formatDate = React.useCallback((date: number | Date, dateformat: DateFormat, ignoreTimeZone: boolean | undefined = undefined): string => {
        const deviceTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let timezoneFormat = "";

        // if api defined timezone and local device timezone differ the check
        // to see if timezone inf needs appending to the format string
        if (!ignoreTimeZone && currentLocale.ianaTimeZone !== deviceTimeZone) {
            // 'p', 'pp, 'ppp' are the time time elements of a time or date and time format
            if (dateformat.indexOf("p") > -1) {
                timezoneFormat = " zzz";
            }
        }
        if (ignoreTimeZone) {
            return format(date, dateformat, { locale: currentLocale.dateLocale })

        } else {
            return formatInTimeZone(date, currentLocale.ianaTimeZone, dateformat + timezoneFormat, { locale: currentLocale.dateLocale })
        }
    }, [currentLocale]);

    const getRelativeDateName = React.useCallback((date: Date, fallBackDateFormat: DateFormat = DateFormat.MediumDate): string => {
        if (isToday(date)) return "Today";
        if (isTomorrow(date)) return "Tomorrow";
        if (isYesterday(date)) return "Yesterday"
        const daysAgo = differenceInDays(new Date(), date);
        // eeee = Full day name e.g. Monday, Tuesday..
        if (daysAgo < 7) return formatDate(date, DateFormat.LongDay);
        // PPP = Long localized date e.g. 7 October 2021...
        return formatDate(date, fallBackDateFormat);
    }, [formatDate]);


    const calculateDateRange = React.useCallback((predefinedDateRangeId: PredefinedDateRangeId, dateRange: DateRange | undefined, dateFormat: DateFormat = DateFormat.ShortDate): SvenDateRange => {
        if (predefinedDateRangeId === "custom" && !dateRange)
            throw new Error("dateRange must be provided if predefinedDateRangeId is custom");

        const now = new Date();
        const startOfThisWeek = startOfWeek(now, { locale: currentLocale.dateLocale });
        const startOfThisMonth = startOfMonth(now);
        const startOfThisQuarter = startOfQuarter(now);
        const startOfThisYear = startOfYear(now);
        const startOfThisDecade = startOfDecade(now);

        let from = now;
        let to = now;

        switch (predefinedDateRangeId) {
            case "today":
                from = startOfToday();
                to = startOfTomorrow();
                break;

            case "tomorrow":
                from = startOfTomorrow();
                to = add(startOfTomorrow(), { days: 1 });
                break;

            case "yesterday":
                from = startOfYesterday()
                to = startOfToday();
                break;

            case "thisWeek":
                from = startOfThisWeek;
                to = add(startOfThisWeek, { weeks: 1 });
                break;

            case "lastWeek":
                from = sub(startOfThisWeek, { weeks: 1 });
                to = startOfThisWeek;
                break;

            case "nextWeek":
                from = add(startOfThisWeek, { weeks: 1 });
                to = add(startOfThisWeek, { weeks: 2 });
                break;

            // case "pastTwoWeeks":
            //     from = sub(startOfThisWeek, { weeks: 2 });
            //     to = startOfThisWeek;
            //     break;

            case "thisQuarter":
                from = startOfThisQuarter;
                to = add(startOfThisQuarter, { months: 3 });
                break;

            case "lastQuarter":
                from = sub(startOfThisQuarter, { months: 3 });
                to = startOfThisQuarter;
                break;

            case "nextQuarter":
                from = add(startOfThisQuarter, { months: 3 });
                to = add(startOfThisQuarter, { months: 6 });
                break;

            case "thisMonth":
                from = startOfThisMonth;
                to = add(startOfThisMonth, { months: 1 });
                break;

            case "lastMonth":
                from = sub(startOfThisMonth, { months: 1 });
                to = startOfThisMonth;
                break;

            case "nextMonth":
                from = add(startOfThisMonth, { months: 1 });
                to = add(startOfThisMonth, { months: 2 });
                break;

            case "thisYear":
                from = startOfThisYear;
                to = add(startOfThisYear, { years: 1 });
                break;

            case "lastYear":
                from = sub(startOfThisYear, { years: 1 });
                to = startOfThisYear;
                break;

            case "nextYear":
                from = add(startOfThisYear, { years: 1 });
                to = add(startOfThisYear, { years: 2 });
                break;

            case "thisDecade":
                from = startOfThisDecade;
                to = add(startOfThisDecade, { years: 10 });
                break;

            case "lastDecade":
                from = sub(startOfThisDecade, { years: 10 });
                to = startOfThisDecade;
                break;

            case "nextDecade":
                from = add(startOfThisDecade, { years: 10 });
                to = add(startOfThisDecade, { years: 20 });
                break;

            case "never":
                from = new Date(0);
                to = new Date(0);
                break;

            case "forever":
                from = Constants.minDate;
                to = Constants.maxDate;
                break;

            case "custom":
                from = dateRange!.from;
                to = dateRange!.to;
                break;

            default:
                throw new Error("No case for predefinedDateRangeId " + predefinedDateRangeId);
        }

        const days = differenceInDays(to, from);
        const formattedDateRange: FormattedDateRange = {
            from: formatDate(getOutgoingAdjustedDate(from), dateFormat),
            to: days > 1 ? formatDate(getOutgoingAdjustedDate(subSeconds(to, 1)), dateFormat) : undefined,
        }

        const svenDateRange: SvenDateRange = {
            dateRange: {
                from: from,
                to: to,
            },
            predefinedDateRange: predefinedDateRanges.find(x => x.id === predefinedDateRangeId)!,
            days: days,
            formattedDateRange: formattedDateRange,
        };
        return svenDateRange;

    }, [formatDate, currentLocale, getOutgoingAdjustedDate]);

    return (
        <LocalizationContext.Provider
            value={{
                cultureInfos,
                currentLocale,
                formatDate,
                getCultureInfo,
                calculateDateRange,
                getIncomingAdjustedDate,
                getDeviceCultureInfoOrFallback,
                getOutgoingAdjustedDate,
                getRelativeDateName,
                getTimeZoneOffsetBetweenDeviceAndWorkspace,
                setLocale,
            }}
        >
            {children}
        </LocalizationContext.Provider>
    );
}
// LocalizationProvider.whyDidYouRender = true;

const useLocalization = () => React.useContext(LocalizationContext);

export { useLocalization, LocalizationProvider };