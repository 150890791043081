import { ApprovalStatus, TimesheetEntryType } from "../services/ApiClient/Schema";

export interface TimesheetFilters {
    projectFilter: string[];
    memberFilter: string[];
    approvalStatusFilter: ApprovalStatus[];
    entryTypeFilter: TimesheetEntryType[],
}

export const defaultTimesheetFilters: TimesheetFilters = {
    projectFilter: [],
    memberFilter: [],
    approvalStatusFilter: [],
    entryTypeFilter: [],
}

export const hasTimesheetFilters = (filters: TimesheetFilters) => {
    return filters.approvalStatusFilter.length > 0
        || filters.projectFilter.length > 0
        || filters.memberFilter.length > 0
        || filters.entryTypeFilter.length > 0;
}