import { DateRange, FormattedDateRange } from "./DateRange";

export type PredefinedDateRangeId =
    | "today"
    | "yesterday"
    | "tomorrow"
    | "thisWeek"
    | "lastWeek"
    | "nextWeek"
    | "nextQuarter"
    | "thisQuarter"
    | "lastQuarter"
    | "thisMonth"
    | "lastMonth"
    | "nextMonth"
    | "thisYear"
    | "lastYear"
    | "nextYear"
    | "thisDecade"
    | "lastDecade"
    | "nextDecade"
    | "never"
    | "forever"
    | "last7days"
    | "last30days"
    | "custom";

export interface PredefinedDateRange {
    id: PredefinedDateRangeId;
    name: string;
}

export const predefinedDateRanges: PredefinedDateRange[] = [
    { id: "tomorrow", name: "Tomorrow" },
    { id: "today", name: "Today" },
    { id: "yesterday", name: "Yesterday" },

    { id: "nextWeek", name: "Next week" },
    { id: "thisWeek", name: "This week" },
    { id: "lastWeek", name: "Last week" },

    { id: "nextMonth", name: " Next month" },
    { id: "thisMonth", name: "This month" },
    { id: "lastMonth", name: "Last month" },

    { id: "nextQuarter", name: "Next quarter" },
    { id: "thisQuarter", name: "This quarter" },
    { id: "lastQuarter", name: "Last quarter" },

    { id: "nextYear", name: "Next year" },
    { id: "thisYear", name: "This year" },
    { id: "lastYear", name: "Last year" },

    { id: "nextDecade", name: "Next decade" },
    { id: "thisDecade", name: "This decade" },
    { id: "lastDecade", name: "Last decade" },

    { id: "never", name: "Never" },
    { id: "forever", name: "Forever" },
    // custom should be at the bottom of the list
    { id: "custom", name: "Custom" },
];

export interface SvenDateRange {
    dateRange: DateRange;
    predefinedDateRange: PredefinedDateRange;
    days: number;
    formattedDateRange: FormattedDateRange;
}

export const defaultSvenDateRange: SvenDateRange = {
    dateRange: {
        from: new Date(0),
        to: new Date(0),
    },
    days: 0,
    formattedDateRange: { from: "???", to: "???" },
    predefinedDateRange: {
        id: "custom",
        name: "Custom",
    }
}