import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Paper from "@material-ui/core/Paper";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useDeviceMeasurements } from "../../../hooks/useDeviceMeasurements";
import { DesktopDialogTitle } from "./DesktopDialogTitle";
import { DraggablePaper } from "./DraggablePaper";
import { ResponsiveModalAppBar } from "./ResponsiveModalAppBar";
import { ResponsiveModalDialogProps } from "./types";
import { GetScrollPaperClass, GetTransition, useDialogStyles } from "./utils";
import { useTheme } from "@material-ui/core/styles";

const ResponsiveModalDialog = ({
    additionalHeaderComponent,
    allowDesktopFullScreen = true,
    allowPhoneFullScreen = true,
    caption,
    captionStyle,
    children,
    close,
    denseTitle,
    dialogContentStyle,
    fullScreenTransition = "slideLeft",
    fullWidth = true,
    helpUrl,
    icon,
    isOpen,
    maxWidth = "sm",
    movable = true,
    noMargin,
    onClosing,
    onDesktopFullScreenChange,
    onEntered,
    onMobileFullScreenChange,
    showCloseButton = true,
    showDividers,
    style,
    title,
    transition = "zoom",
    transitionDuration,
    useBackIconForMobileFullscreenCloseButton = true,
    verticalAlignment = "center",
    zIndex,
    disablePortal = false,
    ...rest
}: ResponsiveModalDialogProps) => {
    const location = useLocation();
    const theme = useTheme();
    const { maybePhone, width } = useDeviceMeasurements();
    const mobileFullScreen = (maybePhone || width < 768) && allowPhoneFullScreen;// useMediaQuery(theme.breakpoints.down(breakpointForMobileFullScreen)) && allowMobileFullScreen;
    const classes = useDialogStyles({
        movable: movable,
        mobileFullScreen: mobileFullScreen,
        noMargin: noMargin,
        zIndex: zIndex,
    });

    // track if the component is still mounted
    const isMountedRef = React.useRef(true);
    React.useEffect(() => () => { isMountedRef.current = false }, []);

    const [desktopFullScreen, setDesktopFullscreen] = React.useState(false);

    // track the location from when the dialog was opened and if it changes then close the dialog
    React.useEffect(() => {
        if (isOpen) {
            const newLocation = `${location.pathname}${location.search}${location.hash}`;
            if (locationRef.current && newLocation !== locationRef.current) close();
            locationRef.current = newLocation;
        }
    }, [location, isOpen, close]);

    // on closing clear the locationRef
    React.useEffect(() => {
        if (!isOpen) {
            locationRef.current = undefined;
        }
    }, [isOpen]);

    // undo full screen on open
    React.useEffect(() => setDesktopFullscreen(false), [isOpen]);

    const locationRef = React.useRef<string | undefined>(undefined);

    const handleEnterFullScreen = () => setDesktopFullscreen(() => true);

    const handleExitFullScreen = () => setDesktopFullscreen(() => false);

    // perform the callback function if defined once we have entered the dialog
    const onEnteredInternal = (node: HTMLElement, isAppearing: boolean) => onEntered ? onEntered() : () => { };

    // perform the callback function if defined when the screen toggles between desktop floating and desktop full screen
    React.useEffect(() => { if (onDesktopFullScreenChange) onDesktopFullScreenChange(desktopFullScreen) }, [onDesktopFullScreenChange, desktopFullScreen]);

    // perform the callback function if defined when the screen toggles between desktop and mobile full screen
    React.useEffect(() => { if (onMobileFullScreenChange) onMobileFullScreenChange(mobileFullScreen) }, [onMobileFullScreenChange, mobileFullScreen]);


    const onCloseInternal = React.useCallback(async () => {
        if (onClosing) {
            const result = await onClosing();
            if (!result) return;
        }
        close();
    }, [close, onClosing]);

    const handleClose = React.useCallback(async (e: any, reason: "escapeKeyDown" | "backdropClick") => {
        if (onClosing) {
            const result = await onClosing();
            if (!result) return;
        }

        close();
    }, [close, onClosing]);

    return (
        <Dialog
            disablePortal={disablePortal}
            PaperComponent={movable ? DraggablePaper : Paper}
            TransitionComponent={GetTransition(mobileFullScreen, fullScreenTransition, transition)}
            TransitionProps={{ onEntered: onEnteredInternal }}
            className={classes.dialog}
            classes={{
                paper: classes.dialogPaper,
                scrollPaper: GetScrollPaperClass(mobileFullScreen || desktopFullScreen, verticalAlignment),
            }}
            fullScreen={mobileFullScreen || desktopFullScreen}
            fullWidth={fullWidth}
            maxWidth={mobileFullScreen || desktopFullScreen ? false : maxWidth}
            onClose={handleClose}
            open={isOpen}
            scroll={"paper"}
            transitionDuration={transitionDuration ? transitionDuration : { enter: theme.transitions.duration.enteringScreen, exit: theme.transitions.duration.leavingScreen }}
            style={style}
        >
            <React.Fragment>

                {(mobileFullScreen) ? (
                    <ResponsiveModalAppBar
                        additionalComponent={additionalHeaderComponent}
                        helpUrl={helpUrl}
                        icon={icon}
                        onClose={onCloseInternal}
                        showCloseButton={showCloseButton}
                        title={title}
                        useBackIconForMobileFullscreenCloseButton={useBackIconForMobileFullscreenCloseButton}
                    />
                ) : (
                    <DesktopDialogTitle
                        helpUrl={helpUrl}
                        icon={icon}
                        dense={denseTitle}
                        additionalComponent={additionalHeaderComponent}
                        allowDesktopFullScreen={allowDesktopFullScreen}
                        desktopFullScreen={desktopFullScreen}
                        movable={movable}
                        onClose={onCloseInternal}
                        onEnterFullScreen={handleEnterFullScreen}
                        onExitFullScreen={handleExitFullScreen}
                        showCloseButton={showCloseButton}
                        title={title}
                    />
                )}
                <DialogContent dividers={showDividers} className={classes.dialogContent} style={dialogContentStyle}>
                    {caption &&
                        <DialogContentText className={classes.dialogContentText} style={captionStyle}>
                            {caption}
                        </DialogContentText>
                    }
                    {children}
                </DialogContent>
            </React.Fragment>
        </Dialog>
    );
}

export { ResponsiveModalDialog };